import React from 'react';
import { Box } from 'theme-ui';
import { CmsContentType } from './enums';
import { CmsContentProps } from './types';

const CmsContent: React.FC<CmsContentProps> = ({
  type = CmsContentType.default,
  children,
}) => <Box variant={`cmsContent.${type}`}>{children}</Box>;

export default CmsContent;
