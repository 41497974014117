import React from 'react';
import { Container } from 'theme-ui';
import RevealAnimationWrapper from '../components/atoms/RevealAnimationWrapper';
import CmsContent from '../components/molecules/CmsContent';
import { CmsContentType } from '../components/molecules/CmsContent/enums';

const PrivacyPolicyPage: React.FC = () => (
  <Container variant="layout.containerNarrow" pt={['2.25rem', '5rem', '10rem']}>
    <RevealAnimationWrapper>
      <CmsContent type={CmsContentType.simple}>
        <h1>Privacy policy</h1>
        <p>
          This Privacy Policy only applies to this website:
          www.luminous.co.uk.We automatically collect information about how you
          use this website and what device or devices you use to do so through
          the use of cookies. We automatically record website usage information
          including your IP address, browser type, operating system, and device
          type used to access the website. This information does not identify
          you personally, and we do not use this information to track or record
          information about individuals.
        </p>
        <h2>How we use the information we collect</h2>
        <p>
          We use the data collected through this website for a variety of
          purposes, including to better understand how people use the website
          and to improve the effectiveness of the website. We do not share this
          information with third parties.
        </p>
        <h2>Data storage and retention</h2>
        <p>
          Data we collect through this website is processed and stored in the
          UK. We take steps to ensure that data is processed according to this
          Policy and to the requirements of applicable law.
        </p>
        <p>
          We do not transfer personal data from the European Economic Area to
          other countries; we use appropriate legal mechanisms to help ensure
          all applicable laws, rights and regulations continue to protect your
          data.
        </p>
        <p>
          We keep any information we collect through this website for as long as
          we need it for business or legal purposes. After that, we destroy it.
        </p>
        <h2>Security of your data</h2>
        <p>
          We’ve established electronic and administrative safeguards designed to
          make the information we collect from this website secure. However, no
          transmission or electronic storage of information is guaranteed to be
          100% secure. We therefore urge you to always use caution when
          transmitting information over the Internet.
        </p>
        <h2>Links to other websites</h2>
        <p>
          This website contain links to other websites and servers that are not
          controlled by Luminous. This Policy does not apply to the privacy
          practices or the content of such linked sites. When you link to
          another site, you are subject to the privacy policy of the new site.
        </p>
        <h2>Changes in ownership or to the Policy</h2>
        <p>
          Information collected through this website may be shared and
          transferred as part of any merger, acquisition, sale of company
          assets, or transition of service to another provider. This also
          applies in the unlikely event of an insolvency, bankruptcy, or
          receivership in which customer and user records would be transferred
          to another entity as a result of such a proceeding.
        </p>
        <p>
          We may update this Policy as necessary to reflect changes we make and
          to satisfy legal requirements. Updates to this Policy will be posted
          to this website. We’ll post a prominent notice of material changes on
          this website.
        </p>
        <h2>Do Not Track notice</h2>
        <p>
          We don’t currently respond to Do Not Track and similar signals. Please
          go to All About Do Not Track for more information.
        </p>
        <p>
          Any customer who wishes to request further information about our
          compliance with these requirements, or who has questions or concerns
          about our privacy practices and policies, can email us at{' '}
          <a
            href="mailto:privacypolicy@luminous.co.uk"
            target="_blank"
            rel="noreferrer"
          >
            privacypolicy@luminous.co.uk
          </a>
          .
        </p>
        <h2>Use of cookies</h2>
        <p>
          We use cookies on our website. Please find our cookie policy here.
        </p>
        <h2>Contact us</h2>
        <p>
          Need to update your information? We’re happy to help you review and
          correct the information we have. For more information, or for help
          with other questions, you can:
        </p>
        <p>
          Email us at{' '}
          <a
            href="mailto:privacypolicy@luminous.co.uk"
            target="_blank"
            rel="noreferrer"
          >
            privacypolicy@luminous.co.uk
          </a>
          .
        </p>
        <address>
          Write to us at <br />
          Data controller <br />
          Luminous <br />
          Second Floor <br />
          35 Great Sutton Street <br />
          London <br />
          EC1V 0DX
        </address>
        <p>
          T:{' '}
          <a href="tel:020 7101 1677" target="_blank" rel="noreferrer">
            020 7101 1677
          </a>
        </p>
        <p>
          For other enquiries, customer service contact numbers can be found at{' '}
          <a
            href="https://www.luminous.co.uk/"
            target="_blank"
            rel="noreferrer"
          >
            www.luminous.co.uk
          </a>
          .
        </p>
      </CmsContent>
    </RevealAnimationWrapper>
  </Container>
);

export default PrivacyPolicyPage;
